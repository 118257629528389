@import "./reset.scss";

/* setting 칼라*/
$theme-color_b: #194fdc;
$hover-color_b: #3569ef;
$light-color_b: #dee7ff;
$light-color_w: #f8f8f8;
$dark-color_b: #000000;
$light-font-w: #828282;

.App {
  @include mobile {
    padding-bottom: 100px;
  }
}
.swiper-grid {
  display: grid;
  width: 100%;
  height: 100%;
}
.container_grid {
  padding: 87px 40px 40px 40px;
  display: grid;
  grid-column-gap: 40px;
  @include tablet {
    padding: 20px;
  }
  @include tablet {
    padding: 100px 20px 50px 20px;
  }
  .column_item {
    display: grid;
    grid-row-gap: 40px;
  }
}

/* Header */
header {
  padding: 20px 40px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  @include tablet {
    padding: 20px;
  }
  @include mobile {
    top: auto;
    bottom: 0;
    padding: 12px;
  }
  .header_container {
    nav {
      display: grid;
      grid-template-columns: 5fr 1fr;
      align-items: center;
      @include tablet {
        display: flex;
        justify-content: space-between;
      }
      @include mobile {
        display: grid;
        grid-template-columns: 3fr 1fr;
      }
      .nav_left {
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        gap: 40px;
        @include tablet {
          display: flex;
        }
        @include mobile {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 0;
        }
        li {
          @include mobile {
            text-align: center;
          }
          a {
            font-size: 24px;
            color: #000000;
            font-weight: bold;
            @include tablet {
              font-size: 16px;
            }
            @include mobile {
              font-size: 12px;
            }
          }
        }
      }
      .nav_right {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include tablet {
          display: block;
        }
        li {
          padding-left: 20px;
          @include mobile {
            text-align: center;
            padding-left: 0;
          }
          a {
            font-size: 16px;
            color: #000000;
            font-weight: bold;
            @include mobile {
              font-size: 12px;
            }
          }
          button {
            border: 0;
            outline: none;
            background-color: rgba(255, 255, 255, 0);
            font-size: 12px;
            font-weight: bold;
            cursor: pointer;
          }
          .global_btn {
            width: 45px;
            height: 45px;
            border-radius: 200px;
            background-color: $dark-color_b;
            color: $light-color_w;
            @include tablet {
              display: none;
            }
          }
          .kakao_btn {
            width: 45px;
            height: 45px;
            border-radius: 200px;
            background-color: rgb(255, 217, 0);
            color: $dark-color_b;
            @include tablet {
              display: none;
            }
          }
        }
      }
    }
  }
}
/* Footer */
footer {
  position: relative;
  // transform: translateY(-100%);
  padding: 20px;
  background-color: #eeeded;
  @include mobile {
    display: none;
  }
  .footer_menu {
    padding-bottom: 5px;
    ul {
      display: flex;
      padding: 0;
      li {
        padding-right: 20px;
        button {
          border: 0;
          outline: none;
          background-color: rgba(255, 255, 255, 0);
          font-size: 14px;
          color: $light-font-w;
        }
      }
    }
  }
  .footer_copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: $light-font-w;
    ul {
      display: flex;
      gap: 10px;
      li {
        button {
          width: 40px;
          height: 40px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          border: 0;
          outline: none;
          background-color: rgba(255, 255, 255, 0);
          border-radius: 200px;
          font-weight: bold;
          cursor: pointer;
        }
        .f_sns_naver {
          background-color: #18c401;
          color: #fff;
        }
        .f_sns_kakao {
          background-color: #ffd900;
        }
        .f_sns_youtub {
          background-color: #f02222;
          color: #fff;
        }
      }
    }
  }
}
/* Left Intro*/
.left_intro {
  position: sticky;
  top: 0;
  height: calc(100vh - 121px);
  height: calc(100dvh - 121px);
  @include tablet {
    position: relative;
  }
  .intro_notice {
    border-radius: 0 !important;
    @include tablet {
      display: none;
    }
    h2 {
      padding-bottom: 40px;
      font-size: 14px;
      font-weight: bold;
    }
    p {
      padding-bottom: 40px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .intro_banner {
    position: relative;
    border-radius: 40px;
    overflow: hidden;
    @include tablet {
      margin-top: 40px;
      height: 500px;
    }
    .mySwiper {
      width: 100%;
      height: 100%;
      .swiper-slide {
        width: 100%;
        padding: 40px;
        background-size: cover;
        .intro_name {
          p {
            color: #fff;
            font-size: 24px;
            @include tablet {
              font-size: 34px;
            }
            @include mobile {
              font-size: 24px;
            }
          }
          a {
            display: inline-block;
            color: #fff;
            font-size: 16px;
          }
          a:hover::after {
            width: 100%;
          }
          a::after {
            content: "";
            display: block;
            width: 0;
            height: 1px;
            transition: all 0.5s;
            transition-timing-function: ease-in-out;
            background-color: #fff;
          }
        }
      }
    }
    .contact {
      position: absolute;
      left: 40px;
      bottom: 40px;
      z-index: 10;
      span {
        display: block;
        font-size: 12px;
        color: #fff;
        @include tablet {
          font-size: 16px;
        }
        @include tablet {
          font-size: 12px;
        }
      }
    }
  }
  .intro_contact {
    align-self: end;
    @include tablet {
      display: flex;
      justify-content: flex-end;
    }
    button {
      display: block;
      width: 100%;
      outline: none;
      border: 0;
      padding: 20px;
      border-radius: 200px;
      font-size: 24px;
      background-color: $theme-color_b;
      color: #fff;
      transition: all 1s;
      &:hover {
        background-color: $hover-color_b;
      }
      @include tablet {
        width: 50%;
      }
    }
  }
}
/* Clients List*/
.clients_lists {
  padding: 100px 0 0;
  h3 {
    margin-bottom: 40px;
    @include mobile {
      width: 50%;
      font-weight: bold;
      font-size: 16px;
      line-height: 1.6;
    }
  }
  ul {
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    @include mobile {
      gap: 20px;
      padding: 0;
    }
    li.clients_list {
      position: relative;
      top: 20px;
      opacity: 0;
      display: flex;
      align-items: center;
      gap: 20px;
      @include mobile {
        flex-basis: 45%;
      }
      .client_img {
        border: 1px solid #e4e4e4;
        border-radius: 200px;
        text-align: center;
        vertical-align: middle;
        img {
          width: 60%;
        }
      }
      p {
        margin: 0 !important;
        @include mobile {
          display: none;
        }
      }
    }
  }
}
/* email form */
.contact_box {
  padding: 100px 0;
  @include mobile {
    padding: 50px 0;
  }
  form {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 1200px;
    input,
    textarea {
      padding: 20px;
      font-family: "NEXON Lv2 Gothic", sans-serif;
      outline: none;
      border: 1px solid #e9e9e9;
      background-color: #f8f8f8;
      resize: none;
      @include mobile {
        font-size: 14px;
      }
      &:focus {
        border-bottom: 1px solid $theme-color_b;
      }
      &:last-child {
        width: 100%;
        max-width: 200px;
        align-self: flex-end;
        border-bottom: 0;
        border-radius: 200px;
        background-color: $theme-color_b;
        cursor: pointer;
        color: #fff;
      }
    }
  }
}
/* Home */
.home {
  padding: 87px 40px 40px 40px;
  opacity: 1;
  height: 100vh;
  grid-template-columns: 1fr 3fr 2fr;
  @include tablet {
    padding: 140px 20px 20px;
    grid-template-columns: none;
    height: auto;
  }
  @include mobile {
    padding: 80px 20px 20px;
  }
  .column_item {
    height: 100%;
  }
  .column_item:nth-child(2) {
    grid-template-rows: auto-fit;
  }
  .column_item:last-child {
    grid-template-rows: 20% auto;
    @include tablet {
      margin: 40px 0 0 0;
    }
  }
  .column_item {
    > div {
      border-radius: 40px;
      overflow: hidden;
    }
    .project_slide {
      border-radius: 0 !important;
      h1 {
        color: $theme-color_b;
        font-size: 50px;
        @include tablet {
          font-size: 60px;
        }
        @include tablet {
          font-size: 30px;
        }
      }
    }
    .intro_contact {
      align-self: end;
      @include tablet {
        display: flex;
        justify-content: flex-end;
      }
      button {
        display: block;
        width: 100%;
        outline: none;
        border: 0;
        padding: 20px;
        border-radius: 200px;
        font-size: 24px;
        background-color: $theme-color_b;
        color: #fff;
        transition: all 1s;
        &:hover {
          background-color: $hover-color_b;
        }
        @include tablet {
          width: 50%;
        }
      }
    }
    .portfolio_banner {
      background-color: $theme-color_b;
      padding: 40px;
      cursor: pointer;
      @include tablet {
        padding: 20px;
      }
      p {
        display: inline-block;
        font-size: 24px;
        color: #fff;
        &::after {
          content: "";
          display: block;
          width: 0;
          height: 1px;
          background-color: #fff;
          transition: all 0.4s;
          @include mobile {
            margin: 0;
            display: none;
          }
        }
        &:hover::after {
          width: 100%;
        }
        @include tablet {
          width: 100%;
          text-align: center;
        }
        @include mobile {
          font-size: 16px;
        }
      }
      span {
        display: block;
        text-align: right;
        color: #b6cbff;
        font-style: italic;
        @include tablet {
          display: none;
        }
      }
    }
    .insta_banner {
      .mySwiper {
        width: 100%;
        height: 100%;
        .swiper-slide {
          background-size: cover;
          .insta_name {
            width: 80%;
            color: #fff;
            padding: 40px;
            h3 {
              font-size: 50px;
              @include tablet {
                font-size: 24px;
              }
            }
          }
        }
        .swiper-scrollbar {
          bottom: 40px;
          left: 50%;
          transform: translateX(-50%);
          width: 80%;
          background-color: rgba(255, 255, 255, 0.6) !important;
          .swiper-scrollbar-drag {
            background: rgba(255, 255, 255, 0.8) !important;
          }
        }
      }
    }
  }
  .left_intro {
    grid-template-rows: auto;
    .intro_notice {
      overflow: visible;
      h2 {
        font-size: 40px;
      }
      p {
        font-size: 20px;
        padding-bottom: 0;
      }
    }
    .intro_contact {
      display: none;
    }
  }
}
/* Portfolio */
.portfolio {
  padding: 100px 40px 40px 40px;
  grid-template-columns: 1fr 5.1fr;
  @include tablet {
    grid-template-columns: auto;
  }
  @include mobile {
    padding: 20px;
  }
  .left_intro {
    @include mobile {
      display: none;
    }
  }
  .portfolio_area {
    .portfolio_tab {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .nav-tabs {
        border: 0;
      }
      h3 {
        color: $theme-color_b;
        font-size: 50px;
        @include mobile {
          font-size: 30px;
        }
      }
      .portfolio_category {
        @include mobile {
          padding: 40px 0 0 0;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          @include mobile {
            justify-content: space-between;
            gap: 0;
          }
          li {
            @include mobile {
              width: 30%;
            }
            button {
              padding: 15px 20px;
              outline: none;
              border: 0;
              border-radius: 200px;
              color: #000;
              background-color: $light-color_w;
              font-size: 14px;
              font-weight: 700;
              &:hover {
                color: #fff;
                background-color: $hover-color_b;
                @include mobile {
                  background-color: #fff;
                  color: #818181;
                }
              }
              &.active {
                color: #fff;
                background-color: $hover-color_b;
                @include mobile {
                  background-color: #fff;
                  color: $theme-color_b;
                  border-bottom: 2px solid $theme-color_b;
                }
              }
              @include mobile {
                padding: 0;
                width: 100%;
                height: 60px;
                border-radius: 0;
                border-bottom: 1px solid #c9c9c9;
                background-color: #fff;
                font-size: 13px;
                color: #818181;
              }
            }
          }
        }
      }
    }
    .portfolio_srch {
      padding: 40px 0;
      form {
        display: grid;
        grid-template-columns: 1fr 5fr;
        align-items: center;
        label {
          color: $theme-color_b;
          font-weight: bold;
        }
        input {
          padding: 20px;
          outline: none;
          border: 1px solid #e9e9e9;
          background-color: #f8f8f8;
          &:focus {
            border-bottom: 1px solid #194fdc;
          }
        }
      }
    }
    .portfolio_gallery {
      .gallery_wrap {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        @include tablet {
          grid-template-columns: repeat(2, 1fr);
        }
        @include mobile {
          grid-template-columns: repeat(1, 1fr);
        }
        .portfolio_box {
          cursor: pointer;
          .ptf_img_thumb {
            height: 200px;
            position: relative;
            border-radius: 4px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
            .img_cover {
              position: absolute;
              right: 20px;
              bottom: 20px;
              padding: 5px 10px;
              object-fit: cover;
              border-radius: 200px;
              background-color: rgba(255, 255, 255, 0.5);
              span {
                font-size: 10px;
              }
            }
          }
          .ptf_txt_thumb {
            padding: 10px 0 0;
            p {
              font-weight: bold;
            }
            span {
              font-size: 14px;
              @include mobile {
                font-size: 12px;
              }
            }
            .ptf_tags {
              margin-top: 10px;
              padding: 0;
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
              font-size: 12px;
              gap: 10px;
              @include mobile {
                font-size: 10px;
              }
              li {
                width: auto;
                padding: 6px 8px;
                border: 1px solid #e4e4e4;
                border-radius: 200px;
              }
            }
          }
        }
      }
    }
  }
}
.newsletter_modal {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .ns_modal_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .modal_card {
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -20%);
    padding: 20px;
    width: 70%;
    max-height: 80%;
    background-color: #f1f1f1;
    overflow-y: scroll;
    @include tablet {
      width: 80%;
    }
    @include mobile {
      width: 100%;
      height: 100vh;
    }
    h2 {
      @include mobile {
        font-size: 20px;
      }
    }
    p {
      @include mobile {
        font-size: 15px;
      }
    }
    .modal_close {
      display: flex;
      justify-content: flex-end;
      padding: 0 0 30px 0;
      button {
        padding: 3px 13px;
        background-color: #e6e6e6;
      }
    }
    .info_txt {
      font-size: 13px;
      font-weight: bold;
    }
    span {
      display: block;
      text-align: center;
      font-size: 14px;
      color: #828282;
    }
  }
}

/* contacrt_us */
.contact_us {
  .ttl_animation {
    h2 {
      font-size: 86px;
      @include tablet {
        font-size: 60px;
      }
      @include mobile {
        font-size: 30px;
      }
    }
  }
  > p {
    font-size: 30px;
    color: $light-font-w;
  }
}
/* about */
.about_page {
  padding-top: 100px;
  .about_intro {
    display: flex;
    justify-content: space-around;
    height: 100vh;
    @include mobile {
      height: auto;
      flex-direction: column;
    }
    h2 {
      font-size: 86px;
      @include tablet {
        font-size: 60px;
      }
      @include mobile {
        font-size: 30px;
      }
      span {
        font-size: 24px;
        color: #bdbdbd;
      }
    }
    .about_txt {
      margin: 30% 0 0 0;
      @include mobile {
        margin: 100px 0;
      }
      p {
        opacity: 0;
        position: relative;
        top: 30px;
        font-size: 40px;
        @include tablet {
          font-size: 24px;
        }
        @include mobile {
          font-size: 16px;
        }
      }
    }
  }
}

.project_area {
  display: flex;
  height: 300vh;
  @include mobile {
    position: relative;
    height: auto;
    flex-direction: column;
  }
  .project_head {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    @include mobile {
      padding: 20px;
      background-color: #f1f5ff;
    }
    .project_txt {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include mobile {
        margin: 30px 0;
        font-size: 14px;
      }
      h1 {
        @include mobile {
          width: 50%;
          font-size: 20px;
          font-weight: bold;
          line-height: 1.6;
        }
      }
    }
  }
  .project_img {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 100vh;
    flex: 1;
    @include tablet {
      top: 25%;
    }
    @include mobile {
      display: none;
    }
    .img_list_wrap {
      @include tablet {
        height: 50%;
      }
      img {
        height: 100%;
      }
    }
  }
}

/* Not found page */
.not_found {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: $light-color_b;
  a {
    cursor: pointer;
    &:hover {
      text-decoration: underline !important;
    }
  }
}

.project_detail {
  grid-template-columns: 1fr 5.1fr;
  @include tablet {
    grid-template-columns: auto;
  }
  .left_intro {
    @include tablet {
      display: none;
    }
  }
  .project_contents {
    .project_intro {
      padding: 40px;
      border: 2px solid $light-color_w;
      @include tablet {
        padding: 20px;
      }
      h2 {
        font-size: 38px;
        @include mobile {
          font-size: 30px;
        }
      }
      p {
        padding: 0 0 10px 0;
      }
    }
    .project_images {
      padding: 40px 0;
      .video_wrap {
        position: relative;
        height: 0;
        padding-bottom: 56.25%;
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    .project_bottom {
      text-align: center;
      @include tablet {
        padding: 25px 0;
      }
      h3 {
        @include mobile {
          font-size: 18px;
        }
      }
    }
    button {
      padding: 10px 20px;
      border-radius: 200px;
      background-color: $theme-color_b;
      transition: all 1s;
      color: #fff;
      outline: none;
      border: 0;
      &:hover {
        background-color: $hover-color_b;
      }
    }
  }
}
/* gallery */
.gallery_page {
  margin: 20px auto;
  width: 50%;
  line-height: 2;
  .gallery-wrap {
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid $light-color_b;
    border-radius: 12px;
    cursor: pointer;
    &:hover {
      background-color: $light-color_b;
    }
    .gallery-title {
      h2 {
        font-weight: bold;
      }
    }
    .gallery-detail {
      margin-top: 20px;
      font-size: 18px;
      h3 {
        font-size: 20px;
        font-weight: bold;
        line-height: 2;
      }
    }
  }
}
