@font-face {
  font-family: "NEXON Lv2 Gothic";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/NEXON Lv2 Gothic.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Common */
html {
  font-size: 16px;
  word-break: keep-all;
  line-height: 1.5;
}
body,
div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
table,
th,
td,
form,
fieldset,
legend,
input,
a,
button,
select,
figure {
  margin: 0;
  padding: 0;
  font-family: "NEXON Lv2 Gothic", sans-serif;
  box-sizing: border-box;
  word-break: keep-all;
}
img,
fieldset {
  border: 0;
}
ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}
em,
address {
  font-style: normal;
}
a {
  text-decoration: none !important;
}
button {
  outline: 0;
  border: none;
  cursor: pointer;
}
/* Responsive layout, breakpoint */

$mobile-max: 767px; //~767
$tablet-max: 1023px; //768 ~ 1023

@mixin mobile {
  @media (max-width: $mobile-max) {
    //~767
    @content;
  }
}
@mixin tablet {
  @media (max-width: $tablet-max) {
    //~ 1023
    @content;
  }
}
